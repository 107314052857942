/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2021 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */

//// CONTROLLER ////
export class TrafficReviewDialogController {
    //Bindable
    public orderItemOverride: any;
    public sessionData: any;
    public isciValidator: any;

    static get $inject() {
        return [
            '$state',
            '$mdDialog',
            'loginService',
            'orderItem',
        ];
    }

    constructor(
        public $state: any,
        public $mdDialog: any,
        public loginService: any,
        public orderItem: any
    ) {
        let vm = this;

        /* BINDABLE : DATA */
        vm.orderItem = orderItem;
        vm.orderItemOverride = {
            isci: vm.orderItem.Spot ? vm.orderItem.Spot.isci : null,
            title: vm.orderItem.Spot ? vm.orderItem.Spot.title : null,
            agency:
                vm.orderItem.Spot && vm.orderItem.Spot.Agency
                    ? vm.orderItem.Spot.Agency.name
                    : null,
            brand:
                vm.orderItem.Spot && vm.orderItem.Spot.Brand
                    ? vm.orderItem.Spot.Brand.name
                    : null,
            houseId: vm.orderItem.houseId,
            advertiser:
                vm.orderItem.Spot && vm.orderItem.Spot.Advertiser
                    ? vm.orderItem.Spot.Advertiser.name
                    : null,
            campaign:
                vm.orderItem.Spot && vm.orderItem.Spot.Campaign
                    ? vm.orderItem.Spot.Campaign.name
                    : null,
            notes: vm.orderItem.DistributionOrder ? vm.orderItem.DistributionOrder.notes : null,
        };
        vm.sessionData = loginService.getSessionData();
        vm.isciValidator =
            vm.orderItem.Spot && vm.orderItem.Spot.hdFlag
                ? /^[a-zA-Z0-9]+[hH]$/
                : /^[a-zA-Z0-9]+[a-gi-zA-GI-Z0-9]$/;
    }

    /* IMPLEMENTATION : BINDABLE */
    cancel() {
        let vm = this;

        vm.$mdDialog.cancel();
    }

    save() {
        let vm = this;
        
        vm.$mdDialog.hide(vm.orderItemOverride);
    }

    $onInit() {
        // Nothing needs to happen on init right now
    }
}
