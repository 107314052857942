/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */

declare var angular: angular.IAngularStatic;

//// CONTROLLER ////
export class UpdateHouseIdDialogController {
    public deliveryOverride: any;
    public originalHouseId: any;

    static get $inject() {
        return [
            '$mdDialog',
            '$scope',
            'delivery'
        ];
    }

    constructor(
        public $mdDialog: any,
        public $scope: any,
        public delivery: any
    ) {
        let vm = this;

        /* BINDABLE : DATA */
        vm.deliveryOverride = {
            isci: vm.delivery.Spot ? vm.delivery.Spot.isci : null,
            title: vm.delivery.Spot ? vm.delivery.Spot.title : null,
            agency:
                vm.delivery.Spot && vm.delivery.Spot.Agency ? vm.delivery.Spot.Agency.name : null,
            brand: vm.delivery.Spot && vm.delivery.Spot.Brand ? vm.delivery.Spot.Brand.name : null,
            houseId: vm.delivery.houseId,
            advertiser:
                vm.delivery.Spot && vm.delivery.Spot.Advertiser
                    ? vm.delivery.Spot.Advertiser.name
                    : null,
            campaign:
                vm.delivery.Spot && vm.delivery.Spot.Campaign
                    ? vm.delivery.Spot.Campaign.name
                    : null,
            notes: vm.delivery.DistributionOrder ? vm.delivery.DistributionOrder.notes : null,
        };

        vm.originalHouseId = angular.copy(vm.delivery.houseId);

        /* EVENTS */
        //Register any event listeners
    }

    /* IMPLEMENTATION : BINDABLE */
    cancel() {
        let vm = this;

        vm.$mdDialog.cancel();
    }

    save() {
        let vm = this;

        vm.deliveryOverride.resubmit = false;
        vm.$mdDialog.hide(vm.deliveryOverride);
    }

    saveAndResubmit() {
        let vm = this;
        vm.deliveryOverride.resubmit = true;
        vm.$mdDialog.hide(vm.deliveryOverride);
    }

    disableSave() {
        let vm = this;
        return vm.originalHouseId === vm.deliveryOverride.houseId;
    }

    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them

    /* INITIALIZATION */
    $onInit() {
        // Nothing to init here
    }
}
